import Image from "next/legacy/image";
import { NextPage } from "next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CustomButton from "components/CustomButton";

const ErrorPage: NextPage = () => {
  return (
    <Box maxWidth="90%" mx="auto" textAlign="center" mt={8}>
      <Image
        src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/common/no-items.png`}
        alt="no items"
        width="420"
        height="243"
      />
      <Typography component="h1" variant="h1" gutterBottom>
        404
      </Typography>

      <Typography textAlign="center" mb={3}>
        The page you are looking for does not exist.
      </Typography>
      <CustomButton href="/">Back to Home Page</CustomButton>
    </Box>
  );
};

export default ErrorPage;
